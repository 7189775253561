import L from 'leaflet'
import 'leaflet-easybutton'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      map: null,
      image: {
        width: 9723,
        height: 13750
      },
      config: {
        fullscreenControl: true,
        minZoom: 0,
        maxZoom: 6,
        crs: L.CRS.Simple,
        tap: false
      },
      markers: [],
      groups: {},
      showGenusPicker: false
    }
  },
  computed: {
    ...mapGetters('Common/Credential', ['userInfo'])
  },
  methods: {
    initialize() {
      delete L.Icon.Default.prototype._getIconUrl
      L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
      })
      let map = L.map('map', this.config)
      let southWest = map.unproject([0, this.image.height], map.getMaxZoom())
      let northEast = map.unproject([this.image.width, 0], map.getMaxZoom())
      let centerPoint = map.unproject(
        [this.image.width / 2, this.image.height / 2],
        map.getZoom()
      )
      let maxBound = new L.LatLngBounds(southWest, northEast)

      L.imageOverlay('/map/map.svg', maxBound).addTo(map)
      map.setMaxBounds(maxBound)
      map.fitBounds(maxBound)
      map.setView(centerPoint, 2)
      map.setZoom(2)
      map.attributionControl
        .removeAttribution()
        .addAttribution('阳光丽景业委会')
      let blueprintLayer = L.imageOverlay('/map/map.jpg', maxBound)
      L.control
        .layers(
          {},
          {
            建筑蓝图: blueprintLayer
          }
        )
        .addTo(map)
      this.map = map

      L.easyButton('fa-filter', () => {
        this.showGenusPicker = true
      }).addTo(map)
    },
    appendMarker(tree, genus) {
      let fillColor = tree.qrcode ? '#ff0000' : '#999999'
      let color = fillColor
      let radius = isNaN(tree.diameter) ? '0.1' : tree.diameter / 100
      // let ref = this.$refs[`tree-${tree._id}`]?.[0]
      // let content = ref.$el.innerHTML
      // eslint-disable-next-line no-console
      // console.log('[Map::drawTrees] ref', ref.$el.innerHTML)
      let marker = L.circle([tree.latitude, tree.longitude], {
        color,
        fillColor,
        fillOpacity: 0.5,
        radius,
        properties: { tree: tree, genus }
      }).bindTooltip(genus.name, {
        direction: 'right'
      })

      marker
        // .bindPopup(content)
        // .on('mouseover', function () {
        //   this.openPopup()
        // })
        // .on('mouseout', function () {
        //   this.closePopup()
        // })
        .on('click', e => {
          let { options } = e.target
          let { tree, genus } = options.properties
          wx.scanQRCode({
            // 微信扫一扫接口
            desc: '请扫描树牌二维码',
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ['qrCode'], // 可以指定扫二维码还是一维码，默认二者都有
            success: res => {
              const qrcode = res.resultStr // 当needResult 为 1 时，扫码返回的结果
              // eslint-disable-next-line no-console
              console.log('扫描得到二维码为：', qrcode, tree)
              this.$dialog
                .confirm({
                  title: '扫码成功',
                  message: tree.qrcode
                    ? `树木「${genus.name}」（${tree.no}）已经绑定了二维码，确定要替换吗？`
                    : `确定为「${genus.name}」（${tree.no}）绑定二维码吗？`,
                  theme: 'round-button',
                  showCancelButton: true,
                  cancelButtonText: '暂时不了',
                  confirmButtonText: '确定'
                })
                .then(async () => {
                  const data = {
                    $url: 'bindQrcode',
                    userInfo: this.userInfo,
                    tree,
                    qrcode
                  }
                  let response = await this.tcb.callFunction({
                    name: 'trees',
                    data
                  })

                  // eslint-disable-next-line no-console
                  console.log('函数请求返回', response)
                  let { result } = response
                  // eslint-disable-next-line no-console
                  console.warn('绑定结果', result)

                  if (result.error) {
                    this.$notify({
                      type: 'danger',
                      message: result.error
                    })
                    return
                  }
                  let {
                    data: { removes, updated }
                  } = result
                  // eslint-disable-next-line no-console
                  console.log('移除了以下树木绑定二维码', removes, updated)
                  this.trees = this.trees.map(tree => {
                    if (removes.includes(tree._id)) {
                      delete tree.qrcode
                      return tree
                    }
                    if (tree._id == updated._id) {
                      tree.qrcode = qrcode
                    }
                    return tree
                  })
                  this.$notify({
                    type: 'success',
                    message: '绑定成功！'
                  })
                  this.updateTreeLists()
                })
                .catch(() => {})
            },
            fail: function (res) {
              this.$notify({ type: 'danger', message: '扫码失败' })
              // eslint-disable-next-line no-console
              console.error('扫描树牌二维码失败', res)
            }
          })
        })
      return marker
    },
    drawTrees() {
      // eslint-disable-next-line no-console
      console.log('[Map::drawTrees] 共有树木种类', this.genuses.length)
      this.markers = []
      let data = this.trees
      let groups = {}
      for (let genus of this.genuses) {
        let group = L.featureGroup()
        let trees = data.filter(tree => tree.genus === genus._id)
        // eslint-disable-next-line no-console
        // console.log('[Map] 找到树木种类', genus.name, items.length)
        for (let tree of trees) {
          let marker = this.appendMarker(tree, genus)
          marker.addTo(group)
          this.markers.push(marker)
        }
        if (trees.length > 0) {
          // eslint-disable-next-line no-console
          // console.log('drawTrees', genus)
          this.map.addLayer(group)
          groups[genus._id] = group
        }
      }
      this.groups = groups
    },
    async updateTreeLists() {
      for (let group of Object.values(this.groups)) {
        this.map.removeLayer(group)
      }

      this.drawTrees()
    },
    onGenusConfirm(e) {
      let genus = ''
      this.selected = e
      // eslint-disable-next-line no-console
      console.log('onGenusConfirm', e)
      if (e != '查看全部') {
        genus = this.genuses.find(g => g.name === e)
        // eslint-disable-next-line no-console
        console.log('find genus', genus)
        if (!genus) {
          this.$notify({ type: 'danger', message: '找不到树木信息' })
          return
        }
      }
      for (let group of Object.values(this.groups)) {
        this.map.removeLayer(group)
      }
      if (genus) {
        this.map.addLayer(this.groups[genus._id])
      } else {
        for (let group of Object.values(this.groups)) {
          this.map.addLayer(group)
        }
      }
      this.showGenusPicker = false
    }
  }
}
