<template>
  <section class="w-screen h-screen">
    <van-skeleton
      v-if="loading"
      class="absolute top-0 left-0 right-0 basic-skeleton"
      :row="5"
    />
    <div
      id="map"
      class="w-full h-full"
    />

    <van-popup
      v-model="showGenusPicker"
      round
      position="bottom"
      :safe-area-inset-bottom="true"
    >
      <van-picker
        show-toolbar
        :columns="names"
        :default-index="genusIndex"
        @cancel="showGenusPicker = false"
        @confirm="onGenusConfirm"
      />
    </van-popup>
  </section>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import leaflet from './mixins/leaflet'
import { mapState } from 'vuex'
import share from '@/mixins/share'
import common from '@/mixins/common'
export default {
  name: 'WorkspaceTrees',
  mixins: [share, leaflet, common],
  data() {
    return {
      loading: true,
      genuses: null,
      trees: null,
      names: [],
      selected: '查看全部'
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb']),
    genusIndex() {
      if (!this.selected) {
        return 0
      }
      return this.names.findIndex(e => e == this.selected)
    }
  },
  async mounted() {
    await Promise.all([this.fetch(), this.prepareWx()])
    let options = {
      title: '扫码绑定',
      desc: '管理员可以通过地图扫码绑定二维码到具体的树木'
    }
    this.updateShareData(options)

    if (typeof Heti != 'undefined') {
      const heti = new Heti('.heti')
      heti.autoSpacing()
    }
    this.initialize()
    this.$nextTick(() => {
      this.drawTrees()
    })
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        const response = await this.tcb.callFunction({
          name: 'trees',
          data: {
            $url: 'map'
          }
        })
        // eslint-disable-next-line no-console
        console.warn('获取到相关树木种类', response)
        if (response.result?.error) {
          this.$notify({ type: 'danger', message: response.result.error })
        } else {
          let { genuses, trees } = response.result.data
          genuses.sort((a, b) => a.name.localeCompare(b.name))

          this.$set(this, 'genuses', genuses)
          this.$set(
            this,
            'names',
            ['查看全部'].concat(genuses.map(item => item.name))
          )
          this.$set(this, 'trees', trees)
        }
      } catch (err) {
        this.$notify({ type: 'danger', message: '服务器内部错误' })
        // eslint-disable-next-line no-console
        console.error('无法获取树木信息', err)
        this.genus = null
      }
      this.$nextTick(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
#map {
  background-color: #f4f5ef;
}
</style>
<style>
.leaflet-control-attribution a,
.leaflet-control-attribution span {
  display: none;
}
</style>